<template>
  <b-overlay
    :show="showOver"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <b-row class="match-height">
        <div class="text-center">
          <p id="cancel-label">
            กรุณารอสักครู่...
          </p>
        </div>
      </b-row>
    </template>
    <!-- <this-header /> -->
    <div class="anan-set-nav-conttainer mt-150">
      <div
        class="col-md-12 mb-2"
      >
        <div>
          <router-link :to="{name : 'lost-package'}">
            <b-button
              variant="primary"
              class="mr-50 col-md-2"
            >
              {{ $t('returnToList') }}
            </b-button>
          </router-link>
          <b-button
            variant="success"
            class="mr-50 col-md-2"
            :disabled="isDisabled"
            @click="Submit()"
          >
            {{ $t('saveData') }}
          </b-button>
        </div>
      </div>

      <b-row class="match-height">
        <div class="row d-flex">
          <div class="col-md-6 col-lg-6 col-sm-12">
            <validation-observer ref="simpleRules">
              <div>
                <div class="card p-2">
                  <h4 class="font-weight-bolder mb-1">
                    {{ $t('reportMissingGoods') }}
                    <hr>
                  </h4>

                  <div>
                    <b-row>
                      <b-col md="12">
                        <b-form-group
                          label-cols="4"
                          label-cols-lg="4"
                          label-cols-md="4"
                        >
                          <template v-slot:label>
                            <span class="text-danger">*</span> {{ $t('orderList') }}
                          </template>
                          <validation-provider
                            #default="{ errors }"
                            name="parcel-code"
                            rules="required"
                          >
                            <v-select
                              id="h-parcel-code"
                              v-model="OrderID"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :state="errors.length > 0 ? false:null"
                              label="orderId_text"
                              :options="OrderList"
                              class="w-100 mr-1"
                            />
                            <span class="text-danger">{{ errors[0] ? $t('key-129') : '' }}</span>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <b-col md="12">
                        <b-form-group
                          label-cols="4"
                          label-cols-lg="4"
                          label-cols-md="4"
                        >
                          <template v-slot:label>
                            {{ $t('mnemonic') }}
                          </template>
                          <b-form-input v-model="Remark" />
                        </b-form-group>
                      </b-col>

                      <b-col md="12">
                        <b-form-group
                          label-cols="4"
                          label-cols-lg="4"
                          label-cols-md="4"
                        >
                          <p>
                            {{ $t('customerUpload') }}
                          </p>
                        </b-form-group>
                      </b-col>

                      <b-col md="12">
                        <b-form-group
                          label-cols="4"
                          label-cols-lg="4"
                          label-cols-md="4"
                        >
                          <template v-slot:label>
                            <b style="color:red;">*</b> {{ $t('transactionEvidence') }}
                          </template>
                          <b-form-file
                            v-model="fileimg"
                            placeholder="Choose a file or drop it here..."
                            drop-placeholder="Drop file here..."
                            @change="checkfile"
                          />

                          <span
                            v-for="(item,index) in fileimg_th"
                            :key="index"
                            class="aws-avatar aws aws-avatar-square aws-avatar-image bordered cursor-pointer"
                          >
                            <img
                              :src="item"
                              accept=".jpg, .png, .jpeg"
                              alt="รูปภาพ"
                              @click="showPicture(item)"
                            >
                            <div class="preview-image-overlays p-0">
                              <i
                                class="fas fa-times-circle cursor-pointer"
                                @click="removeImage(index)"
                              />
                            </div>
                          </span>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </div>
            </validation-observer>
          </div>

          <div class="col-md-6 col-lg-6 col-sm-12">
            <validation-observer ref="simpleRules">
              <div>
                <div class="card">
                  <div class="header-cardx p-2">
                    <div class="d-flex align-items-center justify-content-between mb-2">
                      <h4 class="font-weight-bolder">
                        {{ $t('details') }}
                      </h4>
                    </div>

                    <table class="styled-table mt-n25">
                      <thead>
                        <tr>
                          <th scope="col">
                            {{ $t('sequence') }}
                          </th>
                          <th
                            class="text-left"
                            scope="col"
                          >
                            {{ $t('details') }}
                          </th>
                          <th
                            scope="col"
                            class="w-25"
                          >
                            {{ $t('Refunds') }} ¥
                          </th>
                          <th scope="col">
                            {{ $t('manage') }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(arritem,index) in Detail"
                          :key="index"
                          style="background: #fff; color: black;"
                        >
                          <th
                            class="text-center"
                            scope="col"
                            style="background: #fff; color: black;"
                          >
                            {{ index+1 }}.
                          </th>
                          <td
                            class="text-left"
                            scope="col"
                            style="background: #fff; color: black;"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="item-detail"
                              rules="required"
                            >
                              <b-form-input
                                id="h-item-detail"
                                v-model="arritem.detail"
                                :state="errors.length > 0 ? false:null"
                              />
                              <!-- <span class="text-danger">{{ errors[0] ? $t('key-129') : '' }}</span> -->
                            </validation-provider>
                          </td>
                          <td
                            class="text-right"
                            scope="col"
                            style="background: #fff; color: black;"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="item-amount"
                              rules="required"
                            >
                              <b-form-input
                                id="h-item-amount"
                                v-model="arritem.balance"
                                type="number"
                                :state="errors.length > 0 || arritem.balance == 0 ? false:null"
                              />
                              <!-- <span class="text-danger">{{ errors[0] || arritem.balance == 0 ? $t('requiredField') : '' }}</span> -->
                            </validation-provider>

                          </td>
                          <td
                            class="text-center"
                            scope="col"
                            style="background: #fff; color: black;"
                          >
                            <b-button
                              v-if="isLastPosition(index)"
                              variant="warning"
                              size="sm"
                              @click="addRow()"
                            >
                              <i
                                class="fas fa-plus "
                              />

                            </b-button>
                            <feather-icon
                              v-else
                              icon="TrashIcon"
                              class="text-danger cursor-pointer"
                              @click="removeRow(index)"
                            />
                          </td>
                        </tr>
                      </tbody>
                      <tfoot class="text-center">
                        <tr>
                          <th
                            colspan="2"
                            class="text-right font-weight-bolder"
                          >
                            {{ $t('yuanAmount') }}
                          </th>

                          <th
                            colspan="1"
                            class="text-right"
                          >
                            ¥ {{ Commas(Detail.reduce((total,item) => total + Number(item.balance),0)) }}
                          </th>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </validation-observer>
          </div>
        </div>
      </b-row>
      <vue-image-lightbox-carousel
        ref="lightbox"
        :show="showLightbox"
        :images="images"
        :show-caption="false"
        @close="closeBox()"
      />
    </div>
  </b-overlay>

</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone'
import {
  BButton, BFormGroup, BFormInput, BFormFile,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
// import ThisHeader from '@/views/customer/component/ThisHeader.vue'
import VueImageLightboxCarousel from 'vue-image-lightbox-carousel'
import vSelect from 'vue-select'

export default {
  components: {
    BFormInput,
    BFormGroup,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BFormFile,
    VueImageLightboxCarousel,
    vSelect,
    // ThisHeader,
  },
  data() {
    return {
      images: [],
      showLightbox: false,
      pic: null,
      showOver: false,
      fileimg: null,
      fileimg_th: [],
      required,
      OrderList: [
        { text: 'ORD-0001', value: '1255' },
      ],
      OrderID: null,
      Remark: null,
      Detail: [
        { detail: '', balance: 0, check_balance: 1 },
      ],
      isDisabled: false,
    }
  },
  mounted() {
    this.getOrder()
  },
  methods: {
    showPicture(picture) {
      this.showLightbox = true
      const img = { path: picture }
      this.images.push(img)
    },
    closeBox() {
      this.showLightbox = false
      this.images = []
    },
    time(data) {
      return moment(data).format('YYYY-MM-DD HH:mm:ss')
    },
    addRow() {
      const newRow = {
        detail: '', balance: 0, check_balance: 1,
      }
      this.Detail.push(newRow)
    },
    isLastPosition(data) {
      return data === this.Detail.length - 1
    },
    async getOrder() {
      try {
        const { data: res } = await this.$http.get('/LostPackage/getOrder')
        const updatedRes = res.map(ele => ({ ...ele, orderId_text: `ORD-${ele.orderId_text}` }))
        this.OrderList = updatedRes
        // console.log(res)
      } catch (e) {
        console.log(e)
      }
    },
    Submit() {
      this.isDisabled = true
      const index = this.Detail.findIndex(items => items.balance === 0)
      if (!this.fileimg_th.length) {
        this.Alert(false, 'เกิดข้อผิดพลาด', 'กรุณาแนบหลักฐานการทำรายการ')
        this.isDisabled = false
      } else if (index >= 0) {
        this.SwalError('กรอกข้อมูลให้ครบถ้วน')
        this.isDisabled = false
      } else {
        this.$refs.simpleRules.validate().then(success => {
          if (success) {
            const obj = {
            // eslint-disable-next-line no-underscore-dangle
              order_id: this.OrderID._id,
              order_text: this.OrderID.orderId_text,
              remark_admin: this.OrderID.remark_admin,
              remark_cus: this.Remark,
              detail: this.Detail,
              fileimg: this.fileimg_th,
              rate_yuan: this.OrderID.rate_yuan,
            }
            this.$http.post('/LostPackage/StoreData', obj)
              .then(response => {
                if (response.data.success) {
                    this.$router.push({ name: 'lost-package' })
                    this.Success('บันทึกข้อมูลเรียบร้อย')
                    this.isDisabled = false
                }
              })
              .catch(e => {
                this.SwalError(e)
                this.isDisabled = false
              })
          }
        })
      }
    },
    removeImage(index) {
      this.fileimg_th.splice(index, 1)
      if (this.fileimg_th.length == 0) {
        this.fileimg = null
      }
    },
    removeRow(index) {
      this.$bvModal.msgBoxConfirm('คุณต้องการลบข้อมูลใช่หรือไม่?', {
        title: 'ยืนยันการลบข้อมูล',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      }).then(value => {
        if (value) {
          this.Detail.splice(index, 1)
          this.$bvToast.toast('ลบข้อมูลสำเร็จ', {
            title: 'สำเร็จ',
            variant: 'success',
            solid: true,
            autoHideDelay: 1500,
          })
        }
      })
        .catch(err => {
          console.log(err)
        })
    },
    Alert(status, title, mes) {
      this.$swal({
        icon: `${status ? 'danger' : 'error'}`,
        title: `<h3 style="color: #141414">${title}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-danger',
        },
      })
    },
    checkfile(event) {
      this.fileimg = event.target.files
      const reader = new FileReader()
      const filepath = this.fileimg[0]
      reader.onload = e => {
        // console.log(e.target.result)
        this.fileimg_th.push(e.target.result)
        // console.log(this.fileimg_th)
        // e.target.result
        // console.log(this.namemap)
      }
      reader.readAsDataURL(filepath)
    },
    resize(event) {
      // eslint-disable-next-line no-param-reassign
      event.target.style.height = 'auto'
      // eslint-disable-next-line no-param-reassign
      event.target.style.height = `${event.target.scrollHeight}px`
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-298')}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
    showModal(data) {
      this.pic = data
      this.$refs['my-modal'].show()
    },
    downloadImg() {
      const imageURL = '@/assets/images/anan-img/example/1.jpg'
      const link = document.createElement('a')
      link.href = imageURL
      link.download = 'image.jpg'
      link.click()
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
